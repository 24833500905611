<template>
  <van-empty v-if="listSize == 0" description="暂无内容" />
  <div v-if="listSize > 0" style="margin-bottom: 40px">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        offset="10"
        @load="onLoad"
      >
        <van-cell-group
          inset
          v-for="item in list"
          :key="item.id"
          style="margin-top: 5px"
          @click="toDetail(item)"
        >
          <van-cell :title="'总单数:' +item.student_num" :value="'已完成:'+item.complete_num" />
          <van-cell :title="item.title" value="" />
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Toast } from "vant";
import { useRouter, useRoute } from "vue-router";
import api from "../../service/api";
import { getStorage } from "../../common/js/storeUtil";
// import { getCookie, isAndroid, setCookie } from "../common/js/utils";
// import { isLogin } from "../common/js/isloginUtil";
// import { AESencrypt } from "../common/js/utils";
// import { getStorage, setStorage } from "../common/js/storeUtil";

export default {
  setup() {
    const active = ref(0);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const Route = useRoute(); //获取到值
    const router = useRouter(); //路由传值
    const payType = ref(1);
    const child_name = ref("");
    const school_name = ref("");
    const currentPage = ref(1);
    const payText = ref("未缴费");
    const listSize = ref(1);
    const show = ref(false);
    const className = ref("");
    const refreshing = ref(false);

    onMounted(() => {
      /* eslint-disable */
      className.value = Route.query.className;
      //setTitle("缴费列表");
    });

    const onRefresh = () => {
      currentPage.value = 1;
      api
        .teacherChargeList({
          page: currentPage.value,
          class_id: Route.query.classId,
        })
        .then((res) => {
          refreshing.value = false;
          if (res.data.code == 1001) {
            if (currentPage.value == 1) {
              list.value = [];
            }
            let result = res.data.result;
            let payList = result.data;
            for (let i = 0; i < payList.length; i++) {
              list.value.push(payList[i]);
            }
            listSize.value = list.value.length;
            // 加载状态结束
            currentPage.value++;
            loading.value = false;
            // 数据全部加载完成
            if (list.value.length >= result.total) {
              finished.value = true;
            }
          } else {
            listSize.value = 0;
            loading.value = false;
            finished.value = true;
          }
        })
        .catch((err) => {
          loading.value = false;
          finished.value = true;
          listSize.value = 0;
          Toast(err.message);
        });
    };

    //跳转到详情
    const toDetail = (item) => {
      router.push({
        path: "/chargeDetail",
        query: {
          chargeId: item.id,
          classId:Route.query.classId,
          payType: payType.value,
        },
      });
    };

    const onLoad = () => {
      /**
       * code 不为空时进行请求
       */
      finished.value = false;
      api
        .teacherChargeList({
          class_id: Route.query.classId,
          page: currentPage.value,
        })
        .then((res) => {
          refreshing.value = false;
          if (res.data.code == 1001) {
            if (currentPage.value == 1) {
              list.value = [];
            }
            let result = res.data.result;
            let payList = result.data;
            for (let i = 0; i < payList.length; i++) {
              list.value.push(payList[i]);
            }
            listSize.value = list.value.length;
            // 加载状态结束
            currentPage.value++;
            loading.value = false;
            // 数据全部加载完成
            if (list.value.length >= result.total) {
              finished.value = true;
            }
          } else {
            listSize.value = 0;
            loading.value = false;
            finished.value = true;
          }
        })
        .catch((err) => {
          loading.value = false;
          finished.value = true;
          listSize.value = 0;
          Toast(err.message);
        });
    };

    return {
      list,
      onLoad,
      loading,
      finished,
      toDetail,
      active,
      payType,
      child_name,
      school_name,
      currentPage,
      payText,
      listSize,
      show,
      refreshing,
      onRefresh,
      className,
    };
  },
};
</script>

<style lang="less">
.top {
  position: sticky;
  top: 0;
  z-index: 99;
  .container {
    height: 140px;
    background-color: #f2f2f2;
    .student_info {
      width: 94%;
      margin: 0 auto;
      height: 120px;
      border-radius: 6px;
      background: linear-gradient(to right, #3794fc, #82BAFB);
      box-shadow: 0 2px 5px #1989fa;

      .info {
        display: block;
        width: 92%;
        height: 120px;
        margin: 0 auto;
        .changeBtn {
          float: right;
          margin-top: 20px;
          margin-right: 10px;
        }

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 4px;
          display: inline-block;
          overflow: hidden;
          margin-top: 30px;
        }
        .student_desc {
          display: inline-block;
          margin-left: 10px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            display: block;
            color: #fff;
            font-size: 14px;
            padding: 2px 0;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
